// import

import type {LoaderFunctionArgs} from '@remix-run/node';
import type {MetaFunction} from '@remix-run/react';

import {SignIn} from '@clerk/remix';
import {useSearchParams} from '@remix-run/react';

import {requireAnon} from '~/libs/auth.server';
import {clerkTheme} from '~/libs/clerk';
import * as Modal from '~/modules/Modal';

// config

export const meta: MetaFunction = () => {
  return [{title: 'Sign In 🐾 BrowserCat'}];
};

export const loader = async (args: LoaderFunctionArgs) => {
  await requireAnon(args);

  return {};
};

// export

export default function SignInPage() {
  const [params] = useSearchParams();

  return (
    <Modal.RootAsRoute hideOverlay>
      <Modal.RawContent>
        <SignIn
          appearance={clerkTheme}
          path="/sign-in"
          redirectUrl={params.get('redirect')}
          routing="path"
        />
      </Modal.RawContent>
    </Modal.RootAsRoute>
  );
}
